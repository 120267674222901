import React from "react"
import { graphql, Link } from "gatsby"
import { Avatar } from "gatsby-interface"

const linkCss = theme => ({
  textDecoration: `none`,
  "&:hover [data-name='preview-heading']": {
    color: theme.colors.gatsby,
    textDecoration: "underline",
  },
  // TODO: this looks like it could be a potential a11y problem
  "&:hover .read-more": {
    color: theme.colors.gatsby,
    borderBottom: `1px solid transparent`,
  },
})

const customTitleCss = (hasHero, isHero, isFeatured) => theme => ({
  fontSize: isHero
    ? theme.fontSizes[8]
    : isFeatured
    ? theme.fontSizes[2]
    : theme.fontSizes[5],
  marginBottom: theme.space[3],
  [theme.mediaQueries.desktop]: {
    fontSize: isHero
      ? theme.fontSizes[8]
      : hasHero
      ? theme.fontSizes[5]
      : isFeatured
      ? theme.fontSizes[3]
      : theme.fontSizes[7],
    fontWeight: isHero && hasHero ? theme.fontWeights.extraBold : false,
    maxWidth: "30rem",
    marginBottom: theme.space[4],
  },
})

const paragraphCss = theme => ({
  marginBottom: theme.space[3],
  maxWidth: "35rem",
})

const fontSizeCss = theme => ({
  fontSize: theme.fontSizes[1],
})

const articleCardCss = (hasHero, isHero) => theme => ({
  marginBottom: isHero ? false : theme.space[8],
  ...(hasHero
    ? {
        [theme.mediaQueries.tablet]: {
          display: `inline-block`,
          maxWidth: isHero ? `40rem` : `none`,
          verticalAlign: `top`,
        },
      }
    : {}),
})

const dateCss = isFeatured => theme => ({
  color: theme.colors.text.secondary,
  marginBottom: theme.space[3],
  fontSize: isFeatured ? theme.fontSizes[0] : undefined,
})

const BlogPostPreviewItem = ({
  post,
  Heading = `h3`,
  isHero = false,
  hasHero = false,
  isFeatured = false,
}) => {
  const author = {
    avatarSrc:
      post.author?.node?.authorInfo?.avatar?.localFile.childImageSharp.resize
        .src,
    slug: post.author?.node?.slug,
    name: post.author?.node?.name || `Gatsby Contributor`,
  }
  return (
    <article css={articleCardCss(hasHero, isHero)} key={post.slug}>
      <Link to={`/blog/${post.slug}`} css={linkCss}>
        {!isFeatured && isHero && hasHero && (
          <p css={theme => [dateCss(isFeatured)(theme)]}>{post.date}</p>
        )}
        <Heading
          data-name="preview-heading"
          css={customTitleCss(hasHero, isHero, isFeatured)}
        >
          {post.title}
        </Heading>
        {!isFeatured && (
          <div
            css={theme => ({
              color: theme.colors.text.primary,
              [theme.mediaQueries.desktop]: {
                fontSize: isHero && hasHero ? theme.fontSizes[3] : false,
              },
            })}
          >
            <p css={paragraphCss}>{post.rawExcerpt?.text}</p>
            <p
              css={theme => [
                paragraphCss(theme),
                fontSizeCss(theme),
                {
                  display: `flex`,
                  gap: theme.space[3],
                  alignItems: `center`,
                },
              ]}
            >
              {author.avatarSrc && (
                <Avatar src={author.avatarSrc} label={author.name} size="XS" />
              )}
              <span>{author.name}</span>
              {!isHero && (
                <span>
                  {" "}
                  •{" "}
                  <span css={theme => [dateCss(isFeatured)(theme)]}>
                    {post.date}
                  </span>
                </span>
              )}
            </p>
            <span
              css={theme => ({
                fontSize: theme.fontSizes[0],
                color: theme.colors.grey[50],
                textTransform: `lowercase`,
              })}
            >
              <span
                css={theme => ({
                  color: theme.colors.grey[50],
                })}
              >
                {`# `}
              </span>
              {post.tags.nodes.map((tag, i) => (
                <>
                  {i ? `, ` : ``}
                  <Link
                    key={tag.slug}
                    to={`/blog/tags/${tag.slug}`}
                    css={theme => ({
                      color: theme.colors.grey[50],
                      "&:hover": {
                        color: theme.colors.grey[70],
                      },
                    })}
                  >
                    {tag.name}
                  </Link>
                </>
              ))}
            </span>
          </div>
        )}
        {isFeatured && (
          <p css={theme => [dateCss(isFeatured)(theme)]}>{post.date}</p>
        )}
      </Link>
    </article>
  )
}

export default BlogPostPreviewItem

export const BlogPostPreviewInformationFragment = graphql`
  fragment BlogPostPreviewInformation on WpPost {
    id
    title
    date(formatString: "MMMM Do, YYYY")
    slug
    author {
      node {
        ...AuthorInformation
      }
    }
    tags {
      nodes {
        id
        name
        slug
      }
    }
    rawExcerpt {
      text
    }
  }
`
